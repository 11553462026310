.or {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin: 16px 0;
  box-sizing: content-box;
  $gap: 134px;


  &::after, &::before {
    content: '';
    height: 2px;
    border-radius: 1px;
    background: var(--grey);
    opacity: .3;
    width: calc(61% - $gap / 2);
  }
  &::before {
    width: calc(39% - $gap / 2);
  }
}
