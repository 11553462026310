.checkbox {
  display: inline-flex;
  gap: 8px;

  $checkbox-size: 20px;

  &__label {
    cursor: pointer;
    width: $checkbox-size;
    min-width: $checkbox-size;
    height: $checkbox-size;
    min-height: $checkbox-size;
    position: relative;
    top: 2px;

    &:before {
      transition: transform .3s linear, height .3s linear;
      content: "";
      position: absolute;
      left: 0;
      z-index: 1;
      width: $checkbox-size;
      height: $checkbox-size;
      border: 2px solid var(--grey);
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: $checkbox-size;
      min-width: $checkbox-size;
      height: $checkbox-size;
      min-height: $checkbox-size;
      cursor: pointer;
      background: transparent;
    }
  }

  &__input {
    position: relative;
    margin: 0 16px 0 0;
    cursor: pointer;
    box-shadow: none;
    border: none;
    display: none;

    &:checked ~ label {
      &:before {
        transform: rotate(-45deg);
        height: 8px;
        border-color: var(--green);
        border-top-style: none;
        border-right-style: none;
      }
    }
  }

  &__title {
    display: inline;
    margin-right: 32px;
  }
}
