.before-login-popup {
  &__logo {
    margin: 140px - 24px 0 4px 0;
    display: none;
  }

  &__recovery {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__description, &__form {
    font-weight: 400;
    margin: 8px 0 16px 0;
  }

  &__heading {
    margin: 28px 0 16px 0;
  }

  &__map-link {
    margin: 16px 0 0;
    display: block;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
  }

  &__margin {
    margin: 0 0 16px 0;
  }

  &__input {
    margin: 0 0 4px 0;
  }

  & &__button {
    width: 100%;
    margin: 0 0 16px 0;
    min-height: 52px;
  }

  &__button_type_login {
    min-width: 136px;
    max-width: 136px;
  }

  &__buttons &__button {
    margin: 0;
  }

  &__button-p-mobile {
    display: none;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__checkbox {
    margin: 0 0 16px 0;
  }

  @media (max-width: 420px)  {
    height: 100%;
    overflow-y: auto;
    &__logo {
      display: block;
    }

    &__buttons {
      gap: 8px;
    }

    &__button {
      margin-bottom: 8px;
    }

    &__button_type_login {
      min-width: 106px;
      max-width: 106px;
    }

    &__link {
      margin-top: 8px;
    }

    &__map-link {
      text-align: center;
      margin-bottom: 16px;
    }

    &__social_hidden {
      display: none;
    }

    &__description {
      display: none;
    }

    &__content {
      width: 270px !important; //modal-body overrides it, im too lazy
      margin: 0 auto;
      padding: 0 0 42px 0;
      display: flex;
      flex-direction: column;
    }

    &__button-p-desktop {
      display: none;
    }

    &__button-p-mobile {
      display: block;
    }

    &__heading_center {
      text-align: center;
    }

    &__heading_hidden {
      height: 0;
      margin: 120px - 8px - 24px 0 0 0;
      overflow: hidden;
    }
  }
}

@media (max-width: 420px) {
  :global(#before-login-modal) {
    z-index: 10;
    transition: none;
  }
}
