@import "../../all-modules.global";

.social-block {
  margin-bottom: 16px;
  display: flex;
  gap: 24px;

  &__icon-link {
    min-width: 52px;
    width: 52px;
    height: 52px;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 8px;
    transition: .3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    @include shadow;
    background: var(--navbar);

    &:hover {
      text-decoration: none;
    }

    &:after {
      font: normal normal normal 1.6rem fontello;
      transition: color 150ms ease-in-out;
    }

    &_type_google-plus::after {
      content: "\f1a0";
      color: #dd4b39;
    }

    &_type_google-plus:hover {
      background: #dd4b39;
    }

    &_type_mailru::after {
      content: "\e803";
      color: #0060ff;
    }

    &_type_mailru:hover {
      background: #0060ff;
    }

    &_type_yandex::after {
      content: "\f305";
      color: #ec0000;
    }

    &_type_yandex:hover {
      background: #ec0000;
    }

    &_type_vk::after {
      content: "\f189";
      color: #007bb6;
    }

    &_type_vk:hover {
      background: #007bb6;
    }

    &:hover, &:hover ~ & {
      animation: none;
    }

    &:hover::after {
      color: #fff;
    }
  }

  @media (max-width: 420px) {
    gap: 20px;
  }
}
